'use client';

import CloseIcon from '@/assets/icons/close.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';
import { type HTMLAttributes, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type HeaderSearchFormProps = HTMLAttributes<HTMLFormElement> & {
  dark?: boolean;
  className?: string;
};

const _formSchema = z.object({
  search: z.string().optional(),
});

export const HeaderSearchForm = ({
  dark,
  className,
  ...props
}: HeaderSearchFormProps) => {
  const t = useTranslations('header');
  const pathname = usePathname();
  const { replace } = useRouter();
  const [visibleMobile, setVisibleMobile] = useState(false);

  const form = useForm<z.infer<typeof _formSchema>>({
    defaultValues: {
      search: '',
    },
  });

  useEffect(() => {}, [pathname]);

  const onSubmit = (data: z.infer<typeof _formSchema>) => {
    const params = new URLSearchParams();

    data.search ? params.set('search', data.search) : params.delete('search');

    replace(`/diets?${params.toString()}`);
  };

  return (
    <div className={cn(className)}>
      <Button
        variant='ghost'
        size='icon'
        className='relative z-10 h-8 transition-none ease-in-out lg:hidden'
        onClick={() => setVisibleMobile(!visibleMobile)}
      >
        <SearchIcon
          className={cn(dark || visibleMobile ? 'text-white' : 'text-primary')}
        />
      </Button>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          {...props}
          className={cn(
            'trasition-[top] absolute right-0 z-20 flex w-full px-4 py-3 duration-500 lg:static lg:px-0 lg:py-0',
            visibleMobile ? 'bg-primary top-0' : '-top-[70px]',
          )}
        >
          <FormField
            control={form.control}
            name='search'
            render={({ field }) => (
              <FormItem className='flex-1'>
                <FormControl>
                  <Input
                    type='search'
                    placeholder={t('searchPlaceholder')}
                    enterKeyHint='search'
                    className={cn(
                      dark || visibleMobile
                        ? 'border-white/40 bg-transparent text-white placeholder:text-white/60 focus:border-white focus:placeholder-white/80'
                        : 'border-primary text-primary bg-transparent',
                      'h-10 rounded-lg pl-4 pr-10 transition-colors duration-200 ease-in-out focus-visible:ring-0 focus-visible:ring-offset-0 lg:h-12 lg:min-w-56 lg:pl-4 lg:pr-4 xl:min-w-80',
                    )}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button
            variant='ghost'
            size='icon'
            type='button'
            className='relative z-10 lg:hidden'
            onClick={() => setVisibleMobile(!visibleMobile)}
          >
            <CloseIcon
              className={cn(
                dark || visibleMobile ? 'text-white' : 'text-primary',
              )}
            />
          </Button>
        </form>
      </Form>
    </div>
  );
};
