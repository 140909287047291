import { Link, type LocalizedRoute } from '@/i18n/navigation';

export const NavigationLink = ({
  href,
  label,
  prefetch = false,
  className = '',
  ...rest
}: {
  href: LocalizedRoute;
  label: string;
  prefetch?: boolean;
  className?: string;
}) => {
  return (
    <Link href={href} prefetch={prefetch} className={className} {...rest}>
      {label}
    </Link>
  );
};
