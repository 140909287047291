'use client';

import { HeaderCta } from './header-cta';
import { HeaderSearchForm } from './header-search-form';
import { BrandLogo } from './logo';
import { NavigationDesktop } from './navigation-desktop';
import { MobileNavigation } from './navigation-mobile';
import { TopBar } from './top-bar';
import Panel from '@/assets/icons/panel.svg';
import { buttonVariants } from '@/components/ui/button';
import { getNavigationItems } from '@/data/navigation';
import { useCartStore } from '@/features/cart/components';
import useTopBannersStore from '@/features/top-banners/store/top-banners-store';
import { Link } from '@/i18n/navigation';
import { useScrollPosition } from '@/lib/hooks/use-scroll-position';
import { cn } from '@/lib/utils';
import { type HeaderThemeType } from '@/types/common.types';
import { SignedIn, SignedOut, UserButton } from '@clerk/nextjs';
import { IconShoppingBag } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

type HeaderMainProps = {
  theme?: HeaderThemeType;
};

export const HeaderMain = ({ theme }: HeaderMainProps) => {
  const [innerTheme, setInnerTheme] =
    useState<HeaderMainProps['theme']>('dark-hero');
  const scrollPosition = useScrollPosition();
  const pathname = usePathname();
  const cartStore = useCartStore((state) => state);
  const totalHeight = useTopBannersStore((state) => state.totalHeight);

  const t = useTranslations();
  const items = getNavigationItems().map((item) => ({
    ...item,
    label: t('navigation.' + item.label),
  }));

  useEffect(() => {
    if (theme) {
      const updatedTheme =
        scrollPosition > 1 ? (theme == 'all-light' ? theme : 'light') : theme;
      setInnerTheme(updatedTheme);
    } else {
      const hero = document.querySelector('[data-hero="true"]');
      const newTheme =
        scrollPosition > 1 ? 'light' : hero ? 'dark-hero' : 'dark';
      setInnerTheme(newTheme);
    }
  }, [scrollPosition, pathname]);

  return (
    <div
      className='fixed top-0 z-19 w-full transition-[top]'
      style={{ top: `${totalHeight}px` }}
    >
      <TopBar containerClassName='gap-4 xl:gap-6' theme={innerTheme}>
        <BrandLogo
          dark={innerTheme !== 'light' && innerTheme !== 'all-light'}
        />
        <HeaderSearchForm
          className='ml-auto xl:ml-4'
          dark={innerTheme !== 'light' && innerTheme !== 'all-light'}
        />
        <SignedIn>
          <div className='mr-1 flex items-center lg:hidden'>
            <UserButton />
          </div>
        </SignedIn>
        <NavigationDesktop
          items={items}
          className={cn({ 'text-primary': innerTheme === 'all-light' })}
        />
        <div className='hidden items-center space-x-4 first-letter:hidden lg:ml-auto lg:flex'>
          <SignedIn>
            <div className='flex items-center'>
              <UserButton />
            </div>
          </SignedIn>
          <Link
            href='/panel'
            className={cn(
              innerTheme === 'light' || innerTheme === 'dark-hero'
                ? 'xl:text-primary'
                : 'text-white',
              innerTheme === 'all-light' && 'text-primary',
              'flex h-10 items-center pr-2 underline underline-offset-4 transition hover:no-underline lg:pr-2',
            )}
            data-pw='client-panel-button'
          >
            <SignedOut>
              <Panel className='mr-2.5 h-6 w-6' />
            </SignedOut>
            {t('header.panel')}
          </Link>
          <HeaderCta innerTheme={innerTheme} />
        </div>

        <Link
          href='/cart'
          className={cn(
            buttonVariants({
              variant: 'ghost',
              size: 'icon',
            }),
            'h-10 whitespace-nowrap transition-none lg:hidden',
          )}
          title={t('header.cart')}
          data-pw='mobile-cart-button'
        >
          <div className='relative'>
            <IconShoppingBag
              stroke={1.5}
              className={cn(
                (innerTheme === 'light' || innerTheme === 'all-light') &&
                  'text-primary',
                'size-7',
              )}
            />
            {cartStore.items.length + cartStore.multidietItems.length > 0 && (
              <span
                className={cn(
                  'absolute -right-0.5 -top-0.5 flex h-3.5 w-3.5 items-center justify-center rounded-full text-[10px] leading-none transition-colors duration-200',
                  scrollPosition > 1
                    ? 'bg-brand-yellow'
                    : innerTheme === 'light' ||
                        innerTheme === 'dark-hero' ||
                        innerTheme === 'all-light'
                      ? 'bg-brand-green2 text-white'
                      : 'bg-brand-yellow text-brand-green2',
                )}
              >
                {cartStore.items.length + cartStore.multidietItems.length}
              </span>
            )}
          </div>
        </Link>

        <MobileNavigation
          items={items}
          className='lg:hidden'
          theme={innerTheme}
        />
      </TopBar>
    </div>
  );
};
