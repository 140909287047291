'use client';

import { buttonVariants } from '@/components/ui/button';
import { useCartStore } from '@/features/cart/components';
import { Link } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { type HeaderThemeType } from '@/types/common.types';
import { IconArrowNarrowRight, IconShoppingBag } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';

export const HeaderCta = ({ innerTheme }: { innerTheme?: HeaderThemeType }) => {
  const t = useTranslations();
  const cartStore = useCartStore((state) => state);

  const getLinkVariant = (theme: HeaderThemeType | undefined) => {
    switch (theme) {
      case 'all-light':
        return 'default';
      case 'dark-hero':
      case 'light':
        return 'default';
      default:
        return 'secondary';
    }
  };

  return (
    <>
      {cartStore.items.length > 0 || cartStore.multidietItems.length > 0 ? (
        <Link
          href='/cart'
          className={cn(
            buttonVariants({
              variant: getLinkVariant(innerTheme),
              size: 'lg',
            }),
            'h-12 whitespace-nowrap px-5',
          )}
          title={t('header.cart')}
          data-pw='cart-button'
        >
          {t('header.cart')}
          <div className='relative'>
            <IconShoppingBag stroke={1.5} className='ml-2 size-5' />
            <span className='bg-secondary absolute -right-0.5 -top-0.5 h-2 w-2 rounded-full' />
          </div>
        </Link>
      ) : (
        <Link
          href={{ pathname: '/diets/[[...slug]]', params: { slug: [] } }}
          className={cn(
            buttonVariants({
              variant: getLinkVariant(innerTheme),
              size: 'lg',
            }),
            'h-12 whitespace-nowrap px-5',
          )}
          title={t('header.orderDiet')}
          data-pw='order-diet-button'
        >
          {t('header.orderDiet')}
          <IconArrowNarrowRight className='ml-2 [&>path]:stroke-current' />
        </Link>
      )}
    </>
  );
};
