'use client';

import { useScrollPosition } from '@/lib/hooks/use-scroll-position';
import { cn } from '@/lib/utils';
import { type HeaderThemeType } from '@/types/common.types';

export const TopBar = ({
  children,
  className,
  containerClassName,
  theme,
}: {
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  theme?: HeaderThemeType;
}) => {
  const scrollPosition = useScrollPosition();

  return (
    <header
      className={cn(
        'flex w-full items-center justify-center border-b border-transparent bg-transparent transition-all duration-200',
        scrollPosition > 1
          ? 'text-foreground bg-white/50 py-4 shadow-md backdrop-blur-lg lg:py-6'
          : 'bg-transparent py-4 text-white max-lg:border-white/10 lg:py-6',
        theme === 'all-light' &&
          'border-brand-grey2 bg-white py-4 text-white lg:py-6',
        className,
      )}
    >
      <div className={cn('container flex items-center', containerClassName)}>
        {children}
      </div>
    </header>
  );
};
