import { Img } from '../image';
import { Link } from '@/i18n/navigation';

export const BrandLogo = ({
  slogan = false,
  dark = false,
  link = true,
}: {
  slogan?: boolean;
  dark?: boolean;
  link?: boolean;
}) => {
  let src = '/foodango';

  // add the theme
  if (dark) src += '-white';
  else src += '-green';

  // add the slogan if needed
  if (slogan) src += '-slogan';

  // add the extension
  src += '.svg';

  const Image = (
    <Img
      className='max-w-36 xl:max-w-full'
      priority
      src={src}
      alt='foodango'
      width={215}
      height={48}
    />
  );

  return link ? (
    <Link href='/' className='cursor-pointer'>
      {Image}
    </Link>
  ) : (
    <>{Image}</>
  );
};
