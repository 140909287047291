import IconMenu from '@/assets/icons/burger-menu.svg';
import { NavigationLink } from '@/components/header/navigation-link';
import { SheetClose } from '@/components/ui/sheet';
import { Link } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { type HeaderThemeType } from '@/types/common.types';
import { type NavigationItem } from '@/types/navigation-link.type';
import { SignedOut } from '@clerk/nextjs';
import { Drawer } from '@repo/ui/components/drawer';
import { buttonVariants } from '@repo/ui/components/ui/button';
import { useTranslations } from 'next-intl';

type NavigationDesktopProps = {
  items: NavigationItem[];
  className?: string;
  theme?: HeaderThemeType;
};

export const MobileNavigation = ({
  items,
  className,
  theme,
}: NavigationDesktopProps) => {
  const t = useTranslations('header');

  return (
    <div
      className={cn('flex items-center', className)}
      data-pw='drawer-menu-button'
    >
      <Drawer
        side='right'
        trigger={{
          label: (
            <IconMenu
              className={cn(
                (theme === 'light' || theme === 'all-light') &&
                  '[&_path]:fill-black',
              )}
            />
          ),
          variant: 'ghost',
          className: 'px-0 min-h-8 py-2 transition-none',
        }}
        className='flex flex-col'
      >
        <div className='mt-10 flex grow flex-col' data-pw='drawer-menu'>
          <nav className='items-left flex flex-col space-y-4 text-lg'>
            {items.map((item, index) => (
              <SheetClose key={index} asChild>
                <NavigationLink href={item.href} label={item.label} />
              </SheetClose>
            ))}
            <SheetClose asChild>
              <NavigationLink href='/caterings' label={t('caterings')} />
            </SheetClose>
            <SheetClose asChild>
              <NavigationLink href='/panel' label={t('panel')} />
            </SheetClose>
          </nav>
          <div className='mt-auto flex w-full gap-4'>
            <SignedOut>
              <SheetClose asChild>
                <Link
                  href={{
                    pathname: '/sign-in/[[...sign-in]]',
                    params: { 'sign-in': [] },
                  }}
                  className={cn(
                    buttonVariants({ variant: 'default', size: 'sm' }),
                    'flex-1',
                  )}
                >
                  {t('signIn')}
                </Link>
              </SheetClose>
            </SignedOut>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
