import EmptyCartSvg from '@/assets/icons/empty-cart.svg';
import { Button } from '@/components/ui/button';
import { Link, getLocaleForPathname } from '@/i18n/navigation';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';

export const EmptyCart = () => {
  const t = useTranslations();
  const locale = getLocaleForPathname();
  return (
    <div className='grid h-full w-full place-items-center space-y-2 py-16'>
      <EmptyCartSvg />
      <div className='text-center text-3xl font-semibold'>
        {t('cart.emptyCart.title')}
      </div>
      <div className='text-center text-lg font-medium'>
        <Link href='/diets' locale={locale}>
          <Button variant='secondary' className='px-12 h-14 py-3'>
            {t('cart.emptyCart.cta')}
            <IconArrowNarrowRight stroke={1.25} className='ml-2' />
          </Button>
        </Link>
      </div>
    </div>
  );
};
