import { NavigationLink } from '@/components/header/navigation-link';
import { cn } from '@/lib/utils';
import { type NavigationItem } from '@/types/navigation-link.type';

export const NavigationDesktop = ({
  items,
  className,
}: {
  items: NavigationItem[];
  className?: string;
}) => {
  return (
    <nav className={cn('hidden lg:flex', className)}>
      <ul className='ml-auto flex items-center space-x-4 xl:space-x-8'>
        {items.map((item) => (
          <NavigationLink
            key={item.label}
            className='underline underline-offset-4 hover:no-underline'
            {...item}
          />
        ))}
      </ul>
    </nav>
  );
};
